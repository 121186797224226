import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelperRoutingModule } from './helper-routing.module';
import { CommonInputBoxComponent } from './common-input-box/common-input-box.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CommonInputBoxComponent],
  imports: [
    CommonModule,
    HelperRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [CommonInputBoxComponent]
})
export class HelperModule { }
