import * as _ from 'lodash';
import { environment } from './../../src/environments/environment';
import * as Leaflet from 'leaflet';
class Constant {
    // DateFormatWithTime = 'DD-MM-YYYY HH:mm';
    DateFormatWithTimeHtml = 'DD-MM-YYYY HH:mm';
    DateFormatHtml = 'DD-MM-YYYY';
    timeFormatHtml = 'HH:mm'
    datePickerFormat = 'DD-MM-YYYY HH:mm:ss';
    DateFormat = 'DD-MM-YYYY';
    dateDifferentFormat = 'MM/DD/YYYY';
    DateFormatServerSideDate = "YYYY-MM-DD";
    DateFormatServerSideWithDateTime = "YYYY-MM-DD HH:mm";
    DateFormatServerSideWithDateTimeWithSec = "YYYY-MM-DD HH:mm:ss";
    dashBoardDateFormat = 'DD/MM/YYYY'
    CurrencySymbols = '₹';
    DollarSymbols = '$';
    constructor() {
    }
    // localhost:40211/user/
    localStorage = {
        prefix: 'adloggs-',
        token: 'token',
        user_name: 'user_name',
        dp_url: 'dp_url',
        access: 'access',
        user_role_id: 'user_role_id',
        user_id: 'user_id',
        role_name: 'role_name',
        address: 'address',
        lat: 'lat',
        long: 'long',
        last_name: 'last_name',
        first_name: 'first_name',
        email: 'email',
        phone: 'phone',
        role_id: 'role_id'
    };

    OrderStatusId = {
        created: 1,
        pending: 2,
        assigned: 3,
        pickedUp: 4,
        delivered: 5,
        cancelled: 6,
        deleted: 7,
        arrived: 8,
        outForDelivery: 9,
        arrivedAtPickup: 11,
        undelivered: 10,
        return: 13,
        // returned: 14,
        systemFinished: 15,
        RTODelivered: 14
    }
    OrderStatusName = {
        1: "Created",
        2: "Pending",
        3: "Assigned",
        4: "Picked Up",
        5: "Delivered",
        6: "Cancelled",
        7: "Deleted",
        8: "Arrived",
        10: 'Undelivered',
        11: 'Arrived at pickup',
        9: 'Out for delivery',
        13: 'Return',
        14: 'RTO-Delivered',
        15: 'System Finished',
        20: 'Accepted',//just static add me for ondc
        21: 'In-progress',//just static add me for ondc
        22: 'Completed',//just static add me for ondc
    }

    issueStatusAction = [{ id: 1, name: 'CLOSE' }, { id: 2, name: 'OPEN' }]
    issueSubCategoryAction = [{ id: 'FLM01', name: 'Wrong delivery address' }, { id: 'FLM02', name: 'Delay in delivery' }, { id: 'FLM03', name: 'Delayed delivery' }, { id: 'FLM04', name: 'Packaging' }, { id: 'FLM08', name: 'Incorrectly marked as delivered' }]
    ondcLogisticsName = 'preprod.ondc.adloggs.com';
    Client = {
        Auth: {
            BaseUrl: environment.service.AuthBaseUrl,
        }
    }
    Service = {
        MainMaster: {
            BaseUrl: environment.service.AdloggsApiBaseUrl,
            // trackUrl: environment.service.trackUrl,
            // onboardUrl: environment.service.onboardUrl,

            Auth: {
                Login: 'user/login',
                Logout: 'user/logout'
            },
            order: {
                getAll: 'order/list',
                getById: 'order/'
            },
            igm: {
                getAll: 'igm/getall',
                getById: 'igm/',
                create: 'igm/issue/create',
                issueClose: 'igm/issue/close',
                issueStatusCheck: 'igm/issuestatus/check'
            },
            user: {
                thirdPartygetAll: 'user/lsplist'
            }

        },
    }

}
let constant = new Constant();
export default constant;