import { Injectable } from '@angular/core';
import constant from './../app.constant';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {

  prefix: string = constant.localStorage.prefix;

  constructor() { }

  // addItem(key: string, item: any, notify = false) {
  addItem(key: string, item: any) {
    localStorage.setItem(this.prefix + key, JSON.stringify(item));
  }

  getItem(key: string) {
    let item: any = localStorage.getItem(this.prefix + key);
    try {
      return JSON.parse(item);
    } catch (error) {
      return item;
    }
  }

  removeItem(key: string) {
    localStorage.removeItem(this.prefix + key);
  }

  clearAllItem() {
    localStorage.clear();
  }

}