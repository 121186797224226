import * as moment from 'moment';
import constant from '../app.constant';
import * as _ from 'lodash';


export class CommonLogicService {

    // public localData: any = new LocalStorageService();

    public getUtcOffset() {
        return new Date().getTimezoneOffset() * -1;
    }
    public getCurrentDate() {
        return moment(new Date()).format(constant.DateFormatServerSideWithDateTime);
    }
    // public dateFormaterJsonToString(reqJson: any, format: any) {
    //     let date: any = `${reqJson.year}-${reqJson.month}-${reqJson.day}`
    //     return moment(date, 'YYYY-MM-DD').format(format);
    // }
    // public timeFormaterJsonToString(reqJson: any, format: any) {
    //     let time: any = `${reqJson.hour}-${reqJson.minute}`
    //     return moment(time, 'HH:mm').format(format);
    // }
    // public timeFormaterStringToJson(time: any) {
    //     var reqJson: any = {}
    //     reqJson.hour = parseInt(moment(time, 'HH:mm').format('HH'));
    //     reqJson.minute = parseInt(moment(time, 'mm').format('mm'));
    //     return reqJson;
    // }
    public timeFormaterJsonToString(reqJson: any, format: any) {
        let time: any = `${reqJson.hour}-${reqJson.minute}`
        return moment(time, 'HH:mm').format(format);
    }
    public timeFormaterStringToJson(time: any) {
        var reqJson: any = {}
        reqJson.hour = parseInt(moment(time, 'HH:mm:ss').format('HH'));
        reqJson.minute = parseInt(moment(time, 'HH:mm:ss').format('mm'));
        // reqJson.seconds = parseInt(moment(time, 'HH:mm:ss').format('ss'));
        return reqJson;
    }
    // public dateFormaterStringToJson(date: any) {
    //     var reqJson: any = {}
    //     reqJson.year = parseInt(moment(date, 'YYYY-MM-DD').format('YYYY'));
    //     reqJson.month = parseInt(moment(date, 'YYYY-MM-DD').format('MM'));
    //     reqJson.day = parseInt(moment(date, 'YYYY-MM-DD').format('DD'));
    //     return reqJson;
    // }
    public convertUtcTime(date: any) {
        // var reqJson: any = {}
        return moment.utc(date, constant.DateFormatServerSideWithDateTime).local().format('lll');
    }
    public convertdateTime(date: any) {
        var reqJson: any = {}
        return reqJson.DateTime = moment(date, constant.DateFormatServerSideWithDateTime).format('lll');
    }
    public convertdate(date: any) {
        var reqJson: any;
        return reqJson = moment(date).format(constant.DateFormat);
    }
    // public convertPickUpdateTime(date: any) {
    //     var reqJson: any = {}
    //     return reqJson.DateTime = moment(date).format('DD-MM-YYYY HH:mm');
    // }
    public isObject(value: any) {
        return typeof value === 'object';
    }

    // public dateFormatechanges(date: any, format: any) {
    //     return moment(date, format.fromFormat ? format.fromFormat : null).format(format.toFormat ? format.toFormat : null);
    // }
    public givenDateTimeAndOffsetToChangeUtcTime(dateTime: any) {
        var reqUtcOffset = -1 * this.getUtcOffset();
        var utcTime = moment.utc(dateTime, constant.datePickerFormat).utcOffset(reqUtcOffset).format(constant.DateFormatServerSideWithDateTime);
        return utcTime;
    }
    public differentciateDateTag(fromDate: any, toDate: any) {
        var from_date_time: any = new Date(fromDate);
        var to_date_time: any = new Date(toDate);
        const oneDay = 24 * 60 * 60 * 1000;
        var diffDays = Math.ceil(Math.abs(from_date_time - to_date_time) / oneDay);
        return diffDays;
    }


}

let commonLogics = new CommonLogicService();
export default commonLogics;
