import { Component, OnInit } from '@angular/core';
import { trigger, animate, transition, style, query } from '@angular/animations';
import { fadeInAnimation } from '../../../data/router-animation/router-animation';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
  animations: [fadeInAnimation]
})
export class FullComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public getRouterOutletState(outlet) {
    // return outlet.isActivated ? outlet.activatedRoute : '';
  }

}
