import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { LocalStorageService } from "./local-storage.service";
import { catchError } from 'rxjs/operators';
import commonLogics from './common-logic.service'
@Injectable()

export class JwtInterceptor implements HttpInterceptor {

  constructor(private router: Router, private localstr: LocalStorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUserToken: any = this.localstr.getItem('token')


    const headers = {
      "x-access-token": currentUserToken,
      "current-date": commonLogics.getCurrentDate(),
      "utc-offset": commonLogics.getUtcOffset().toString()
    };


    if (currentUserToken) {
      request = request.clone({
        setHeaders: headers
      });
    }
    return next.handle(request)
    // .pipe(
    //   catchError((error: HttpErrorResponse) => {
    //     // console.log("error",error)
    //     // console.log("error",error.status)
    //     // console.log("error",error.error)
    //     // console.log("error",error.error.message)
    //     this.localstr.clearAllItem()

    //     let errMsg = '';
    //     // Client Side Error
    //     if (error.error instanceof ErrorEvent) {
    //       errMsg = `Error: ${error.error.message}`;
    //     } else {  // Server Side Error
    //       errMsg = `Error Code: ${error.status},  Message: ${error.message}`;
    //     }
    //     if (error.status == 401) {
    //       this.router.navigate(["/login"])
    //     }
    //     return throwError(errMsg)
    //   })
    // )

  }
}